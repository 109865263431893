import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RentIncome from "./components/RentIncome/RentIncome";
import Trends from "../src/components/Trends/Trends";
import UpcomingRent from "./components/UpcomingRent/UpcomingRent";
import Properties from "./components/Properties/Properties";
import Tenants from "./components/Tenants/Tenants";
import { paths } from "./Utility/Constants";
import APICall from "./Utility/APICall";
import { useAuth } from "./Utility/AuthProvider";
import CustomComponent from "./components/RecentPayments/CustomComponent.jsx";

function Dashboard() {
  const { jwt } = useAuth();
  const navigate = useNavigate();
  const [rentData, setRentData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await APICall(
          "GET",
          paths.rent.overview,
          undefined,
          undefined,
          jwt
        );
        console.log("Response:", response);
        const rentDataFromResponse = response?.response?.rent_data || [];
        setRentData(rentDataFromResponse);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    return () => {};
  }, [jwt]);

  console.log("rent-overview data -", rentData);

  function handleRecentPaymentClick() {
    navigate("/Payments");
  }

  const handleViewAllUpcomingRent = () => {
    navigate("/Reports", { state: { rentData } });
  };

  const handleViewAllProperties = () => {
    navigate("/MyProperties");
  };

  const handleTenantsClick = () => {
    navigate("/MyProperties/TenantsList");
  };

  return (
    <div>
      <div className="text-xs">
        <p className="text-xl font-black">Home</p>
        <div className="px-10 py-5 gap-10">
          <div className="flex justify-between items-center px-4 py-5">
            <p>Rent Income</p>
            <p>View Rent Collection</p>
          </div>
          <section className="w-full">
            <RentIncome />
          </section>
          {/* <div className="overflow-hidden">
            <div className="flex justify-between items-center px-4 py-5">
              <p>Trends</p>
            </div>
            <Trends />
          </div> */}
        </div>

        {/* <div className="overflow-hidden">
          <div className="flex justify-between items-center px-4 py-5">
            <p>Trends</p>
          </div>
          <Trends data={rentData} />
        </div> */}

        <div>
          <div style={{ marginRight: "20px" }}>
            <div className="flex justify-between items-center px-4 py-5">
              <p>Recent Payments</p>
              <button
                className="text-blue-700"
                onClick={handleRecentPaymentClick}
              >
                View All
              </button>
            </div>
            <div className="shadow-md">
              <CustomComponent displayAll={false} />
            </div>
          </div>
          {/* <div>
            <div className="flex justify-between items-center px-4 py-5">
              <p>Upcoming Rent</p>
              <button
                style={{ color: "blue", cursor: "pointer" }}
                onClick={handleViewAllUpcomingRent}
              >
                View All
              </button>{" "}
            </div>
            <UpcomingRent />
          </div> */}
        </div>

        <div className="flex items-start justify-between gap-10">
          <div className="w-2/3">
            <div className="flex justify-between items-center px-4 py-5">
              <p>Upcoming Rent</p>
              <button
                style={{ color: "blue", cursor: "pointer" }}
                onClick={handleViewAllUpcomingRent}
              >
                View All
              </button>
            </div>
            <div>
              <UpcomingRent />
            </div>
          </div>
          <div className="w-1/3">
            <div className="flex justify-between items-center px-4 py-5">
              <p>Tenants</p>
              <button className="text-blue-700" onClick={handleTenantsClick}>
                View All
              </button>
            </div>
            <Tenants />
          </div>
        </div>

        <div className="flex justify-between items-center">
          {/* <div>
            <div className="flex justify-between items-center px-4 py-5">
              <p>Tenants</p>
              <button className="text-blue-700" onClick={handleTenantsClick}>
                View All
              </button>
            </div>
            <Tenants />
          </div> */}
          <div className="w-full">
            <div className="flex justify-between items-center px-4 py-5">
              <p>Properties</p>
              <button
                className="text-blue-700"
                onClick={handleViewAllProperties}
              >
                View All
              </button>
            </div>
            <div>
              <Properties />
            </div>
          </div>
        </div>
      </div>
    </div>
    // </Layout>
  );
}

export default Dashboard;
