import React, { useState, useEffect } from "react";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import { useAuth } from "../../Utility/AuthProvider";
import Lottie from "lottie-react";
import loaderAnimation from "../../assets/animations/loader.json";
import PaymentModal from "../Modals/PaymenModal";
import GenericTable from "../GenericTable"; // Import the GenericTable component
import { MdDeleteForever } from "react-icons/md";

const CustomComponent = ({ displayAll }) => {
  const { jwt } = useAuth();
  const [data, setData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [nextPageToken, setNextPageToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const fetchData = async (pageToken) => {
    try {
      if (nextPageToken === "END") return;
      setLoading(true);
      const response = await APICall(
        "POST",
        paths.payments.fetch,
        { next_page_token: pageToken ?? "" },
        undefined,
        jwt
      );

      let newPayments = response.response.payments;
      if (!displayAll) {
        newPayments = newPayments.slice(0, 5); // Limit to 5 records if displayAll is false
      }

      const updatedData = [...data, ...newPayments]; // Append new data

      setData(updatedData);
      setGroupedData(groupByMonth(updatedData));
      setNextPageToken(response.response.next_page_token);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  console.log("Data state before deletion 💋:", data);

  // Handle delete with a specific entity_id
  const handleDelete = async (transaction_id, month, year, isCheck) => {
    try {
      console.log("Entity ID to delete:", transaction_id);

      // Construct the query string
      const queryParams = new URLSearchParams({
        transaction_id: transaction_id,
        month: month,
        year: year,
        is_check: isCheck,
      }).toString();

      const urlWithParams = `${paths.payments.delete}?${queryParams}`;
      console.log("API Request URL: ", urlWithParams);

      const response = await APICall(
        "DELETE",
        urlWithParams,
        undefined,
        undefined,
        jwt
      );

      console.log("API Response:", response);

      if (response.status) {
        // Remove the deleted item from the data array
        const updatedData = data.filter(
          (item) => item.entity_id !== transaction_id
        );

        // Update state to reflect the deletion
        setData(updatedData);
        setGroupedData(groupByMonth(updatedData)); // Update grouped data if applicable

        console.log("Deleted successfully. Updated data:", updatedData);
      } else {
        console.error("Failed to delete:", response.error);
      }
    } catch (error) {
      console.error("Error during delete operation:", error);
    }
  };

  console.log("Data state after deletion 😈💋  :", data);

  useEffect(() => {
    console.log("Data updated:", data);
  }, [data]);

  const groupByMonth = (data) => {
    return data.reduce((acc, item) => {
      const month = new Date(item.local_date).toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(item);
      return acc;
    }, {});
  };

  useEffect(() => {
    fetchData(null);
  }, [jwt]);

  const handleNext = () => {
    if (nextPageToken && nextPageToken !== "END") {
      fetchData(nextPageToken);
    }
  };

  const downloadCSV = () => {
    const headers = [
      "Description",
      "Tenant Details",
      "Date",
      "Status",
      "Amount",
    ];
    const rows = data.map((item) => [
      `"${item.nature.charAt(0) + item.nature.slice(1)}"`,
      `"${item.tenant_name}"`,
      `"${item.local_date}"`,
      `"${item.payment_status}"`,
      `"${item.local_amount}"`,
    ]);

    let csvContent =
      "data:text/csv;charset=utf-8," +
      headers.join(",") +
      "\n" +
      rows.map((row) => row.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "payments_data.csv");
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  const paymentDetailsModal = (item) => {
    console.log("Row clicked:", item); // Add a console log here
    setSelectedPayment(item); // Store the selected payment details
    setShowPaymentModal(true); // Show the modal
  };

  const calculateSerialNumber = (monthIndex, index) => {
    const previousMonthsData = Object.keys(groupedData)
      .slice(0, monthIndex)
      .reduce((acc, month) => acc + groupedData[month].length, 0);
    return previousMonthsData + index + 1;
  };

  // Prepare the columns and rows for the GenericTable
  const columns = [
    { id: "serialNo", label: "Record No." },
    { id: "nature", label: "Deposit Type" },
    { id: "buildingUnit", label: "Building & Unit" },
    { id: "tenant", label: "Tenant Details" },
    { id: "date", label: "Date" },
    { id: "rentMonth", label: "Rent For Month" },
    { id: "status", label: "Status" },
    { id: "amount", label: "Amount" },
  ];

  const rows = Object.keys(groupedData).flatMap((month, monthIndex) =>
    groupedData[month].map((item, index) => ({
      serialNo: calculateSerialNumber(monthIndex, index),
      nature: item.nature,
      buildingUnit: `(B)-${item.building_name}(U)-${item.unit_name}`,
      tenant: item.tenant_name,
      date: item.local_date,
      rentMonth: item.month,
      status: item.payment_status === "success" ? "Paid" : "Not Paid",
      amount: item.amount,
      onClick: () => {
        console.log("Row clicked:", item); // Log to verify onClick
        paymentDetailsModal(item);
      }, // Add a log here to see if it fires
    }))
  );

  return (
    // <div className="flex flex-col item-start">
    <div className="table-container" style={{ marginBottom: "20px" }}>
      <button
        style={{
          padding: "10px",
          backgroundColor: "green",
          marginBottom: "20px",
          color: "white",
          borderRadius: "10px",
          float: "right",
        }}
        onClick={downloadCSV}
      >
        Download Displayed Record
      </button>
      {/* <button
        style={{
          fontSize: 10,
          maxWidth: 150,
          padding: "5px",
          backgroundColor: "green",
          // marginBottom: "20px",
          color: "white",
          borderRadius: "10px",
        }}
        onClick={downloadCSV}
      >
        Download Displayed Record
      </button> */}

      {/* Use the GenericTable here */}
      {/* <GenericTable
        columns={columns}
        rows={rows}
        rowsPerPageOptions={[10, 25, 50, 100]}
        tableHeight={450}
        loading={loading} // Pass loading state
        loaderAnimation={loaderAnimation} // Pass the loader animation JSON
      /> */}

      {/* <PaymentModal
        isVisible={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        paymentDetails={selectedPayment}
      /> */}

      {/* {loading && (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Lottie animationData={loaderAnimation} loop={true} />
        </div>
      )} */}
      <table
        style={{ width: "100%", borderCollapse: "collapse" }}
        className="bg-white border border-gray-200"
      >
        <thead
          style={
            {
              // position: "sticky",
              // top: "0",
              // zIndex: "1",
              // backgroundColor: "#fff",
            }
          }
          className="text-xs bg-gray-100"
        >
          <tr>
            <th style={tableHeaderStyle}>Record No.</th>
            <th style={tableHeaderStyle}>Deposit Type</th>
            <th style={tableHeaderStyle}>Building & Unit</th>
            <th style={tableHeaderStyle}>Tenant Details</th>
            <th style={tableHeaderStyle}>Date</th>
            <th style={tableHeaderStyle}>Rent For Month</th>
            <th style={tableHeaderStyle}>Status</th>
            <th style={tableHeaderStyle}>Amount</th>
            <th style={tableHeaderStyle}></th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedData).map((month, monthIndex) => (
            <React.Fragment key={month}>
              {groupedData[month].map((innerItem, index) => (
                <tr
                  key={index}
                  style={tableRowStyle}
                  className="text-xs hover:bg-gray-50 cursor-pointer"
                  onClick={() => paymentDetailsModal(innerItem)} // Trigger modal
                >
                  <td style={tableCellStyle}>
                    {calculateSerialNumber(monthIndex, index)}
                  </td>
                  <td style={tableCellStyle}>
                    {innerItem.nature ? innerItem.nature.toUpperCase() : ""}
                  </td>
                  <td style={tableCellStyle}>
                    {innerItem.building_name && innerItem.unit_name
                      ? `(B)-${innerItem.building_name.toUpperCase()}(U)-${innerItem.unit_name.toUpperCase()}`
                      : ""}
                  </td>
                  <td style={tableCellStyle}>{innerItem.tenant_name}</td>
                  <td style={tableCellStyle}>{innerItem.local_date}</td>
                  <td style={tableCellStyle}>{innerItem.month}</td>
                  <td style={tableCellStyle}>
                    {innerItem.payment_status === "success"
                      ? "Paid"
                      : "Not Paid"}
                  </td>
                  <td style={tableCellStyle}>{innerItem.amount}</td>
                  <td>
                    {/* delete button for a payment */}
                    {innerItem.payment_id === "manual" ? (
                      <td>
                        <MdDeleteForever
                          size={20}
                          color="red"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(
                              innerItem.entity_id,
                              innerItem.month,
                              innerItem.year,
                              innerItem.is_check || false // Use the actual `is_check` field if available
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    ) : (
                      <td></td>
                    )}
                  </td>
                </tr>
              ))}
              <PaymentModal
                isVisible={showPaymentModal}
                onClose={() => setShowPaymentModal(false)}
                paymentDetails={selectedPayment}
                deleteRecord={handleDelete}
              />
            </React.Fragment>
          ))}
          {loading && (
            <tr>
              <td colSpan="7" style={{ textAlign: "center" }}>
                <Lottie animationData={loaderAnimation} loop={true} />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {nextPageToken === "END" && (
        <p className="text-center p-4">******END OF PAYMENTS DATA******</p>
      )}

      {displayAll && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <button
            onClick={handleNext}
            disabled={!nextPageToken || nextPageToken === "END" || loading}
            style={{
              padding: "10px 30px",
              backgroundColor: nextPageToken === "END" ? "gray" : "blue",
              color: "white",
              borderRadius: "10px",
              cursor: nextPageToken === "END" ? "not-allowed" : "pointer",
            }}
          >
            Show More
          </button>
        </div>
      )}
    </div>
  );
};

const tableHeaderStyle = {
  backgroundColor: "#F5F7FA",
  padding: "10px",
  borderBottom: "1px solid #E1E8F0",
  textAlign: "left",
};

const tableRowStyle = {
  borderBottom: "1px solid #E1E8F0",
};

const tableCellStyle = {
  padding: "10px",
  textAlign: "left",
};

const buttonStyle = {
  padding: "10px 30px",
  backgroundColor: "blue",
  color: "white",
  cursor: "pointer",
  borderRadius: "10px",
};

const disabledButtonStyle = {
  padding: "10px 30px",
  borderRadius: "10px",
  backgroundColor: "gray",
  color: "white",
  cursor: "not-allowed",
};

export default CustomComponent;
