import React from "react";
// import "./payments.css";
import RecentPayments from "../RecentPayments/RecentPayments";

function Payments() {
  return (
    <div className="container">
      <p className="text-xl">Payments</p>

      <div className="">
        <div>
          <RecentPayments />
        </div>
      </div>
    </div>
  );
}

export default Payments;
