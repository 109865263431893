import React, { useState, useRef } from "react";
import "./paidmodal.css";
import { IoAddSharp, IoClose } from "react-icons/io5";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useUser } from "../../../Utility/UserProvider";
import APICall from "../../../Utility/APICall";
import { paths } from "../../../Utility/Constants";
import { useAuth } from "../../../Utility/AuthProvider";
import { cloudStorageBucket } from "../../../Utility/Constants";
import { GetUploadMediaBaseLocation } from "../../../Utility/APICall";
import { getDownloadURL } from "firebase/storage";
import FileUpload from "../../../Utility/FileUpload";
import FileDownload from "../../../Utility/FileDownload";
// import {storage} from '../../Utility/firebase'
import { storage } from "../../../Utility/firebase";
import { ref, uploadBytesResumable } from "firebase/storage";

const PaidModal = ({
  onClose,
  onMarkAsPaid,
  selectedTenant,
  setSelectedPaymentType,
  setSelectedFile,
}) => {
  const { jwt } = useAuth();
  const { user } = useUser();
  const fileInputRef = useRef(null);

  const [formData, setFormData] = useState({
    paymentMethod: "", // Selected payment method
    description: "", // Payment description
    // file: [], // Uploaded file
  });

  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  // const [imageFiles, setImageFiles] = []
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [file, setFile] = useState([]);
  const [downloadURL, setDownloadURL] = useState("");
  const [description, setDescription] = useState("");
  const [transcationID, setTransactionID] = useState(null);

  const [imageDimensions, setImageDimensions] = useState({
    height: 0,
    width: 0,
  });

  // const handleFileUpload = (file) => {
  //   setFile(file);s
  // };

  const handleAddTransactionWithoutMediaToDatastore = async () => {
    const body = {
      paymentMethod: formData.paymentMethod, // Updated to reflect payment method
      description: formData.description, // Added description field
      status: "active",
    };

    try {
      setIsLoading(true);
      const res = await APICall(
        "POST",
        paths.transaction.add,
        body,
        undefined,
        jwt
      );

      if (res.status === "success") {
        setIsLoading(false);
        setFormData({
          paymentMethod: "",
          description: "",
          file: null,
        });
        setTransactionID(res.entity_id);
        alert("Payment marked as successful!");
        return res.response.entity_id;
      } else {
        console.error("Error in marking payment as successful");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddMediaToTransactionToDatastore = async (
    building_id,
    downloadURL
  ) => {
    try {
      const media = {
        name: formData.file.name,
        location: downloadURL, // Use Firebase-generated download URL
        type: formData.file.type,
        size: formData.file.size,
      };

      const body = {
        building_id: building_id,
        media: JSON.stringify(media),
      };

      const response = await APICall(
        "PUT",
        paths.transaction.update,
        body,
        undefined,
        jwt
      );
      console.log(response);

      alert("Media added successfully");
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddMediaWithFirebaseStorageUpload = async (
    transactionId,
    fileName
  ) => {
    try {
      // const building_id = await handleAddTransactionWithoutMediaToDatastore();
      const baseUploadLocation = GetUploadMediaBaseLocation(
        cloudStorageBucket.transaction,
        user
      );
      const location = `${baseUploadLocation}${transactionId}/${fileName}`;
      const reference = ref(storage, location);
      const uploadTask = uploadBytesResumable(reference, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.error("Upload failed", error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref); // Corrected usage
          console.log("Download URL:", downloadURL);
          await handleAddMediaToTransactionToDatastore(
            transactionId,
            downloadURL
          );
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageLoad = (e) => {
    const { naturalHeight, naturalWidth } = e.target;
    setImageDimensions({ height: naturalHeight, width: naturalWidth });
  };

  const handleFileChange = (event) => {
    // let files = []

    console.log("event.target.files --- ", event.target.files);

    const selectedFile = event.target.files[0];

    setFile([...file, selectedFile]);

    // setImageFiles({...imageFiles, imageFiles})

    const acceptedFormats = ["image/jpeg", "image/png"];

    if (selectedFile && acceptedFormats.includes(selectedFile.type)) {
      setFormData((prev) => ({ ...prev, file: selectedFile }));
      setImagePreview(URL.createObjectURL(selectedFile));
    } else {
      alert("Please select a valid file format (JPG or PNG).");
    }
  };

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentType(method);
    setSelectedPaymentMethod(method);
    setFormData((prev) => ({ ...prev, paymentMethod: method }));
  };

  const handleClearFile = () => {
    setFormData((prev) => ({ ...prev, file: null }));
    setImagePreview(null);
  };

  const handleSubmit = async () => {
    const { paymentMethod, description } = formData;

    const handleFirebaseStorageUpload = async (transactionID) => {
      try {
        const fileLocations = await Promise.all(
          file.map(async (item) => {
            const baseUploadLocation = GetUploadMediaBaseLocation(
              cloudStorageBucket.transaction,
              user
            );
            const location = `${baseUploadLocation}${transactionID}/${item.name}`;

            console.log("🥸 Uploading to location: ", location);

            const reference = ref(storage, location);
            // const task = reference.putFile(item.path);
            // Start the upload
            const task = uploadBytesResumable(reference, item);

            // Wait for upload task completion
            await new Promise((resolve, reject) => {
              task.on(
                "state_changed",
                (snapshot) => {
                  console.log(
                    `${snapshot.bytesTransferred} bytes transferred out of ${snapshot.totalBytes}`
                  );
                },
                reject, // Handle error
                resolve // On success
              );
            });

            return location; // Return the location for the current file
          })
        );

        console.log("Uploaded file locations: ", fileLocations);
        return fileLocations;
      } catch (error) {
        console.error("‼️ Error during file upload: ", error);
        throw new Error("File upload failed");
      }
    };

    if (!paymentMethod) {
      alert("Please select a payment method.");
      return;
    }

    try {
      setIsLoading(true);

      // Step 1: Upload files (if any)
      let fileLocations = [];
      if (file.length > 0) {
        const transactionID = transcationID; // Fetch transaction ID first
        fileLocations = await handleFirebaseStorageUpload(transactionID);
      }

      // Step 2: Prepare media array
      const media = file.map((item, index) => ({
        name: item.name,
        location: fileLocations[index], // Associate correct location
        type: item.type,
        height: imageDimensions.height,
        width: imageDimensions.width,
        size: item.size,
        description: "", // Add descriptions if needed
      }));

      // Step 3: Prepare payload
      const body = {
        description,
        media: media.length > 0 ? JSON.stringify(media) : null,
        tenant_name: selectedTenant.tenant_name,
        building_name: selectedTenant.building_name,
        unit_name: selectedTenant.unit_name,
        agreement_id: selectedTenant.agreement_id,
        building_id: selectedTenant.building_id,
        unit_id: selectedTenant.unit_id,
        year: selectedTenant.year,
        nature: selectedTenant.nature,
        type: selectedTenant.type,
        payment_status: "success",
        payment_mode: selectedPaymentMethod,
        payment_id: selectedPaymentMethod.split("-")[0],
        amount: selectedTenant.amount,
        month: selectedTenant.month,
      };

      console.log("Payload body: ", body);

      // Step 4: Make API call to save transaction
      const response = await APICall(
        "POST",
        paths.transaction.add,
        body,
        null,
        jwt
      );

      console.log("API Response: ", response);

      if (response.status === "success") {
        alert("Payment marked as successful!");
        onMarkAsPaid();
        onClose();
      } else {
        console.error("Error submitting payment", response);
      }
    } catch (error) {
      console.error("Error during submission: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const paymentMethods = [
    "manual-UPI",
    "manual-Cash",
    "manual-Cheque",
    "manual-NEFT",
    "manual-Others",
  ];

  return (
    <div className="modal-overlay">
      <div className="modal">
        <IoCloseCircleOutline style={{ float: "right" }} onClick={onClose} />
        <div className="modal-content">
          <h2 style={{ fontSize: "30px", textAlign: "center" }}>
            Mark as Paid
          </h2>
          <div className="payment-methods">
            <h3 className="pt-2 text-center">Select Payment Method</h3>
            <div id="payment-type-container">
              {paymentMethods.map((method) => (
                <button
                  key={method}
                  type="button"
                  onClick={() => handlePaymentMethodChange(method)}
                  style={{
                    border: "1px solid #000",
                    padding: "10px 20px",
                    cursor: "pointer",
                    background:
                      formData.paymentMethod === method ? "blue" : "#fff",
                    color: formData.paymentMethod === method ? "#fff" : "#000",
                    borderRadius: "5px",
                    outline: "none",
                    margin: "5px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {method.split("-")[1]}
                </button>
              ))}
            </div>
          </div>
          <div className="description-section">
            <h3 className="p-4 text-center text-blue">+Add Description</h3>
            <input
              type="text"
              value={formData.description}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              placeholder="Add your description here..."
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #000",
                borderRadius: "5px",
              }}
            />
          </div>
          <div className="file-input-container">
            {!formData.file && (
              <div onClick={() => fileInputRef.current.click()}>
                <IoAddSharp />
                <p>Upload Receipt</p>
                <p>Supported files: PNG, JPG</p>
              </div>
            )}
            <input
              type="file"
              ref={fileInputRef}
              multiple
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            {imagePreview && (
              <div className="file-preview">
                <img
                  src={imagePreview}
                  alt="Preview"
                  onLoad={handleImageLoad}
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                />
                <IoClose className="clear-file" onClick={handleClearFile} />
              </div>
            )}
          </div>
          <button id="submit-paid" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? "Submitting..." : "Submit Payment"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaidModal;
