import React, { useEffect, useState } from "react";
import { useAuth } from "../../Utility/AuthProvider";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import CircularInitial from "./CircularInitial";
import OverdueStatus from "../Reports/components/OverdueStatus";

const ReusableComponent = ({ name, address, amount, status }) => {
  const { jwt } = useAuth();
  const [rentData, setRentData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await APICall(
          "GET",
          paths.rent.overview,
          undefined,
          undefined,
          jwt
        );
        const rentDataFromResponse = response?.response?.rent_data || [];
        setRentData(rentDataFromResponse);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [jwt]); // Run effect when jwt changes

  console.log("Upcoming Rent -", rentData);

  return (
    <div className="rounded-lg p-4" style={{ backgroundColor: "white" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {rentData.map((rentalData, index) => (
          <div key={index} className="hover:shadow-lg rounded-md px-4">
            {rentalData.data.slice(0, 1).map((rental, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  marginBottom: "10px",
                  marginTop: "10px",
                  alignItems: "center",
                }}
                className="text-xs"
              >
                <CircularInitial
                  firstName={rental.first_name}
                  lastName={rental.last_name}
                />
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    marginLeft: "10px",
                    justifyContent: "space-between",
                    textAlign: "left",
                  }}
                >
                  <div>
                    {rental.first_name} {rental.last_name}
                    <div className="flex justify-start items-center gap-2">
                      <p className="text-xs">{rental.building_name}</p>
                      <p className="text-xs">{rental.unit_name}</p>
                    </div>
                  </div>

                  <div>
                    <p>{rental.local_amount}</p>
                    <span className="text-xs">
                      {rental.due_in && <p className="text-red-500">Overdue</p>}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReusableComponent;
