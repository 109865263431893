import React from "react";
import { useState, useEffect } from "react";
import APICall from "./Utility/APICall";
import { paths } from "./Utility/Constants";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import LoginForm from "./LoginForm";
import OTP from "./OTP";
import AddInfo from "./AddInfo";
import Dashboard from "./Dashboard";
import { useAuth } from "./Utility/AuthProvider";
import ViewAll from "./components/UpcomingRent/ViewAll";
import MyProperties from "./components/Properties/MyProperties";
import Reports from "./components/Reports/Reports";
import Payments from "./components/PaymentsTab/Payments";
import WhatsAppComponent from "./components/Reports/components/WhatsappComponent";
import Units from "./components/Properties/Units";
import Buildings from "./components/Properties/Buildings";
import AddNewBuilding from "./components/Properties/AddNewBuilding";
import AddNewUnit from "./components/Properties/AddNewUnit";
import UnitDetails from "./components/Properties/UnitDetails";
import BuildingDetails from "./components/Properties/BuildingDetails";
import TenantsList from "./components/Properties/TenantsList";
import AddNewTenant from "./components/Tenants/AddNewTenant";
import Agreements from "./components/Agreements/Agreements";

function App() {
  const { jwt } = useAuth();
  const [buildingData, setBuildingData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [agreementData, setAgreementData] = useState([]);
  const [tenantData, setTenantData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const buildingResponse = await APICall(
          "GET",
          paths.building.fetch,
          undefined,
          undefined,
          jwt
        );
        setBuildingData(buildingResponse.response);
        const unitResponse = await APICall(
          "GET",
          paths.unit.fetch,
          undefined,
          undefined,
          jwt
        );
        setUnitData(unitResponse.response);
        const tenantResponse = await APICall(
          "GET",
          paths.tenant.fetch,
          undefined,
          undefined,
          jwt
        );
        setTenantData(tenantResponse.response);
        const agreementResponse = await APICall(
          "GET",
          paths.agreement.fetch,
          undefined,
          undefined,
          jwt
        );
        setAgreementData(agreementResponse.response);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [jwt]);

  // console.log("APP.JS DATA for tenant-", buildingData);
  // console.log("APP.JS DATA for agreement -", unitData);

  return (
    <div className="App">
      <Router>
        <Routes>
          {jwt !== "" ? (
            <>
              {/* Layout with Sidebar and Navbar */}
              <Route path="/" element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route path="ViewAll" element={<ViewAll />} />
                <Route path="Payments" element={<Payments />} />
                <Route path="MyProperties" element={<MyProperties />} />
                <Route
                  path="Reports"
                  element={
                    <Reports
                      agreement={agreementData}
                      tenant={tenantData}
                      building={buildingData}
                      unit={unitData}
                    />
                  }
                />
                <Route
                  path="Units"
                  element={
                    <Units
                      building={buildingData}
                      unit={unitData}
                      loading={loading}
                    />
                  }
                />
                <Route path="Buildings" element={<Buildings />} />
                <Route path="AddNewBuilding" element={<AddNewBuilding />} />
                <Route path="AddNewUnit" element={<AddNewUnit />} />
                <Route path="AddNewTenant" element={<AddNewTenant />} />
                <Route path="MyProperties/:unitId" element={<UnitDetails />} />
                <Route
                  path="MyProperties/BuildingDetails/:buildingId"
                  element={<BuildingDetails />}
                />
                <Route path="Agreements" element={<Agreements />} />
                <Route
                  path="MyProperties/TenantsList"
                  element={<TenantsList />}
                />
                <Route
                  path="WhatsAppComponent"
                  element={<WhatsAppComponent />}
                />
              </Route>

              {/* Layout without Sidebar and Navbar */}
              <Route path="/AddInfo" element={<AddInfo />} />
            </>
          ) : (
            <>
              <Route path="/" element={<LoginForm />} />
              <Route path="/OTP" element={<OTP />} />
              <Route path="/AddInfo" element={<AddInfo />} />
            </>
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
