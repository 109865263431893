import React from "react";
import "./upcomingrent.css";
import ReusableComponent from "./ReusableComponent";

function UpcomingRent() {
  return (
    <div className="">
      <ReusableComponent />
    </div>
  );
}

export default UpcomingRent;
