import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CustomImage from "../../Utility/CustomImage";
import { useAuth } from "../../Utility/AuthProvider";
import { shortIndianNumberingFormat } from "../../Utility/Utils";
import { MdDeleteForever } from "react-icons/md";

// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';

const deleteModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-30%, -50%)",
  width: "80%",
  maxWidth: 500,
  bgcolor: "background.paper",
  // border: "2px solid",
  borderRadius: 5,
  boxShadow: 50,
  p: 4,
  overflowY: "auto", // Handles content overflow for smaller screens
  maxHeight: "100vh",
};

export default function PaymentModal({
  isVisible,
  onClose,
  paymentDetails,
  deleteRecord,
}) {
  const { jwt } = useAuth();

  const [childModalOpen, setChildModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isBasicModalOpen, setIsBasicModalOpen] = useState(false);

  const handleOpenChildModal = (image) => {
    setSelectedImage(image);
    setChildModalOpen(true);
  };

  const handleCloseChildModal = () => {
    setChildModalOpen(false);
    setSelectedImage(null);
  };

  if (!isVisible) return null;

  const handleDeleteModalOpen = () => {
    setIsBasicModalOpen(true);
  };

  const handleDelete = () => {
    deleteRecord();
  };

  return (
    <>
      {/* Parent Modal */}
      <Modal
        open={isVisible}
        onClose={onClose}
        aria-labelledby="payment-modal-title"
        aria-describedby="payment-modal-description"
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.2)", // Lighter black with 20% opacity
          },
        }}
      >
        <Box sx={style}>
          <Box className="flex items-center justify-between">
            <Typography id="payment-modal-title" variant="h6" component="h2">
              Payment Reciept
            </Typography>
            {paymentDetails.payment_id != "manual" ? (
              " "
            ) : (
              <MdDeleteForever
                size={30}
                className="hover:text-red-500 cursor-pointer"
                onClick={() => setIsBasicModalOpen(true)}
              />
            )}

            {/* {isBasicModalOpen && <BasicModal />} */}
          </Box>
          <Typography
            id="payment-modal-description"
            sx={{ mt: 2 }}
            className="flex gap-4"
          >
            <div className="text-xs">
              <p>
                <strong>Building:</strong> {paymentDetails.building_name}
              </p>
              <p>
                <strong>Flat:</strong> {paymentDetails.unit_name}
              </p>
              <p>
                <strong>Tenant:</strong> {paymentDetails.tenant_name}
              </p>
            </div>
            <div className="text-xs">
              <p>
                <strong>Amount:</strong>{" "}
                {shortIndianNumberingFormat(paymentDetails.amount)}
              </p>
              <p>
                <strong>Status:</strong>{" "}
                {paymentDetails.payment_status === "success"
                  ? "Paid"
                  : "Not Paid"}
              </p>
              <p className="">
                <strong>Remarks:</strong>{" "}
                <p className="text-green-500 text-nowrap">
                  {" "}
                  {paymentDetails.description
                    ? paymentDetails.description
                    : "Not Provided"}
                </p>
              </p>
            </div>
          </Typography>
          <div className="mt-4">
            <h3 className="font-bold mb-2 text-xs">Proof:</h3>
            {paymentDetails.media && paymentDetails.media.length > 0 ? (
              <div className="grid grid-cols-3">
                {paymentDetails.media.map((mediaItem, index) => (
                  <div
                    key={index}
                    className="m-auto cursor-pointer border-2 rounded-lg border-gray-500 p-2 hover:opacity-75"
                    onClick={() => handleOpenChildModal(mediaItem.location)}
                  >
                    <CustomImage
                      location={mediaItem.location}
                      jwt={jwt}
                      className="object-contain max-h-[100px] max-w-[100px]"
                      params={{ quality: "high" }}
                      onError={() =>
                        console.log(`Error loading image for media ${index}`)
                      }
                    />
                  </div>
                ))}
              </div>
            ) : (
              <p>No Proof uploaded.</p>
            )}
          </div>
        </Box>
      </Modal>

      {/* Basic Modal */}
      <Modal
        open={isBasicModalOpen}
        onClose={() => setIsBasicModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteModalStyle}>
          <Typography>
            Are you sure you want to delete this record...
          </Typography>
          <div className="flex">
            <Button onClick={handleDelete}>Yes</Button>
            <Button onClick={() => setIsBasicModalOpen(false)}>No</Button>
          </div>
        </Box>
      </Modal>

      {/* Child Modal */}
      <Modal
        open={childModalOpen}
        onClose={handleCloseChildModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            maxWidth: "50%",
            width: "auto",
            maxHeight: "80%",
            objectFit: "contain",
            bgcolor: "gray",
          }}
        >
          {/* <Typography id="child-modal-title" variant="h6" component="h2">
            Full Image
          </Typography> */}
          {selectedImage && (
            <div className="flex justify-center items-center mt-4">
              <CustomImage
                location={selectedImage}
                jwt={jwt}
                className="object-contain scale-50 max-h-[100vh] max-w-full"
                params={{ quality: "high" }}
                onError={() => console.log("Error loading full image")}
              />
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseChildModal}
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
}
