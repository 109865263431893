import React from "react";
import CustomComponent from "./CustomComponent";

function RecentPayments() {
  return (
    <div className="">
      <CustomComponent displayAll={true} />
    </div>
  );
}

export default RecentPayments;
