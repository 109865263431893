import React from "react";
import RentOverviewTable from "./components/RentOverViewTable";

function Reports({ mobileNumber, tenant, agreement, building, unit }) {
  return (
    // <Layout>
    <div className="">
      <div className="flex justify-between gap-10 p-4 ">
        {/* <div>
            <p>Overview</p>
            <RentIncome />
          </div> */}
      </div>
      <div>
        <p>Recent Rents</p>
        <RentOverviewTable
          tenant={tenant}
          agreement={agreement}
          building={building}
          unit={unit}
        />
      </div>
    </div>
    // </Layout>
  );
}

export default Reports;
