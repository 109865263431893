import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout";
import APICall from "../../Utility/APICall";
import { paths } from "../../Utility/Constants";
import { useAuth } from "../../Utility/AuthProvider";
import Buildings from "./Buildings";
import Units from "./Units";
import buildingsIcon from "../../assets/buildingsicon.png";
import { FaHouse } from "react-icons/fa6";
import { HiUsers } from "react-icons/hi2";
import unitsIcon from "../../assets/unitsicon.png";
import tenantsIcon from "../../assets/tenantsicon.png";
import { Link } from "react-router-dom";
import { IoIosAddCircle } from "react-icons/io";
import CustomImage from "../../Utility/CustomImage"; // Import the CustomImage component
import VacantBtn from "../../components/Buttons/VacantBtn";
import RentedBtn from "../../components/Buttons/RentedBtn";
import { Height } from "@mui/icons-material";
import { display, fontSize, height, textAlign } from "@mui/system";
import { useUser } from "../../Utility/UserProvider";
import Lottie from "lottie-react";
import loaderAnimation from "../../assets/animations/loader.json";

function MyProperties() {
  const { jwt } = useAuth();
  const [tenantData, setTenantData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [activeComponent, setActiveComponent] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const buildingResponse = await APICall(
          "GET",
          paths.building.fetch,
          undefined,
          undefined,
          jwt
        );
        setTenantData(buildingResponse.response);
        const unitResponse = await APICall(
          "GET",
          paths.unit.fetch,
          undefined,
          undefined,
          jwt
        );
        setUnitData(unitResponse.response);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [jwt]);

  const totalUnits = tenantData.reduce((total, building) => {
    if (
      building &&
      building.no_of_units &&
      !isNaN(parseInt(building.no_of_units))
    ) {
      return total + parseInt(building.no_of_units);
    } else {
      return total;
    }
  }, 0);

  // console.log("What is tenantData ", tenantData);
  const totalTenants = tenantData.reduce((total, building) => {
    if (
      building &&
      building.no_of_tenants &&
      !isNaN(parseInt(building.no_of_tenants))
    ) {
      return total + parseInt(building.no_of_tenants);
    } else {
      return total;
    }
  }, 0);

  const handleViewAll = (component) => {
    setActiveComponent(component);
  };

  const handleViewAllBuildings = () => {
    navigate("/buildings");
  };

  const handleViewAllUnits = () => {
    navigate("/units");
  };

  const handleEditUnitClick = (unit) => {
    navigate(`/MyProperties/${unit.unit_name}`, { state: unit });
  };

  const handleEditBuildingClick = (building) => {
    navigate("/AddNewBuilding", { state: { building } });
  };

  return (
    <div className="">
      <div className="">
        <p className="pb-5 text-lg font-black">
          Welcome,{`${user.first_name}`}
        </p>
        <p className="pb-10 text-lg">
          We have added some demo units and buildings in your account, you can
          edit & add agreement.
        </p>
        <section className="section-container flex justify-start items-center gap-10">
          <Link to="/Buildings" className="text-none">
            <div className="card bg-[#EFF4FE] flex justify-between items-center p-10 rounded-xl w-60">
              <div>
                <p className="text-xl font-bold">Buildings</p>
                <p>{tenantData ? tenantData.length : 0}</p>
              </div>
              <div>
                <img
                  src={buildingsIcon}
                  alt="Buildings Icon"
                  className="h-20 w-20"
                />
              </div>
            </div>
          </Link>

          <Link to="/Units" className="text-none">
            <div className="card bg-[#FBEAED] flex justify-between items-center p-10 rounded-xl w-60">
              <div>
                <p className="text-xl font-bold">Units</p>
                {totalUnits}
              </div>
              <div>
                <img src={unitsIcon} alt="Units Icon" className="h-20 w-20" />
              </div>
            </div>
          </Link>

          <Link to="/MyProperties/TenantsList" className="text-none">
            <div className="card bg-[#E1F8F2] flex justify-between items-center p-10 rounded-xl w-60">
              <div>
                <p className="text-xl font-bold">Tenants</p>
                {totalTenants}
              </div>
              <div>
                <img
                  src={tenantsIcon}
                  alt="Tenants Icon"
                  className="h-20 w-20"
                />
              </div>
            </div>
          </Link>
        </section>
      </div>

      <div className="flex justify-center items-center rounded-lg gap-4 mt-10 mr-10">
        {/* Building Container */}
        <div className=" mb-2 w-full md:w-1/2">
          <div className="flex justify-between items-center p-4">
            <p className="text-xl font-bold flex items-center gap-2">
              Buildings{" "}
              <IoIosAddCircle
                color="green"
                size={30}
                onClick={() => navigate("/AddNewBuilding")}
              />
            </p>
            <button
              className="add-button bg-blue-500 text-white py-2 px-4 rounded-lg"
              onClick={handleViewAllBuildings}
            >
              View All
            </button>
          </div>

          {activeComponent === "buildings" && (
            <Buildings buildings={tenantData} />
          )}

          {loading ? (
            <Lottie animationData={loaderAnimation} loop={true} />
          ) : (
            <div className="flex flex-col gap-2 justify-center rounded-xl p-4 bg-white">
              {tenantData.length > 0 &&
                tenantData.slice(0, 2).map((building, index) => (
                  <div
                    key={index}
                    className="rounded-lg flex items-center border-b border-black"
                  >
                    <div className="rounded-xl overflow-hidden object-cover">
                      <CustomImage
                        location={building.media.location}
                        jwt={jwt}
                        params={{ quality: "high" }}
                        className="h-40 w-40"
                        onError={() => console.log("Image Load Error")}
                      />
                      {/* {console.log("building location -", building.media)} */}
                    </div>
                    <div className="pl-4">
                      <p>{building.building_name}</p>
                      <p className="text-gray-500 text-sm">
                        {building.address}
                      </p>
                      <div className="flex items-center justify-between gap-4 text-sm mt-2">
                        <p className="flex items-center gap-1">
                          <FaHouse color="grey" size={15} />
                          {building.number_of_flats} Flats{" "}
                        </p>
                        <p className="flex items-center gap-1">
                          <HiUsers color="grey" />
                          {building.no_of_tenants} Tenants
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>

        {/* Unit Container */}
        <div className="mb-2 w-1/2">
          <div className="flex justify-between items-center p-4">
            <p className="text-xl font-bold flex items-center gap-2">
              Units{" "}
              <IoIosAddCircle
                color="green"
                size={30}
                onClick={() => navigate("/Units")}
              />
            </p>
            <button
              className="add-button bg-blue-500 text-white py-2 px-4 rounded-lg"
              onClick={handleViewAllUnits}
            >
              View All
            </button>
          </div>
          {activeComponent === "units" && <Units units={unitData} />}
          {loading ? (
            <Lottie animationData={loaderAnimation} loop={true} />
          ) : (
            <div className="rounded-xl p-4 bg-white flex flex-col gap-2 ">
              {unitData.slice(0, 2).map((unit, index) => (
                <div
                  key={index}
                  className="rounded-lg flex items-center border-b border-black"
                >
                  <div className="rounded-xl overflow-hidden">
                    <CustomImage
                      location={
                        unit && unit.media && unit.media.length > 0
                          ? unit.media[0].location
                          : undefined
                      }
                      jwt={jwt}
                      className="h-40 w-40"
                      params={{ quality: "high" }}
                      onError={() => console.log("Image Load Error")}
                    />
                  </div>
                  <div className="pl-4">
                    <p>{unit.unit_name}</p>
                    <p className="text-gray-500 text-sm">
                      {unit.building_name}
                    </p>
                    <div className="mt-2">
                      {unit.occupancy_status ? (
                        <RentedBtn className="bg-green-200 text-xs">
                          Rented
                        </RentedBtn>
                      ) : (
                        <VacantBtn className="bg-red-200 text-xs">
                          Vacant
                        </VacantBtn>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MyProperties;
