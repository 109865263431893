import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";

const CircularMeter = ({ totalFlats, rentedFlats, vacantFlats }) => {
  const data = [
    { id: 0, value: rentedFlats, label: "Rented Flats" },
    { id: 1, value: vacantFlats, label: "Vacant Flats" },
  ];
  // Calculate percentage of rented and vacant flats
  // const rentedPercentage = (rentedFlats / totalFlats) * 100;
  // const vacantPercentage = (vacantFlats / totalFlats) * 100;

  // Calculate the radius and circumference of the meter
  // const radius = 50;
  // const circumference = 2 * Math.PI * radius;

  // Calculate the stroke dash offset for rented and vacant flats
  // const rentedStrokeDashOffset =
  //   ((100 - rentedPercentage) / 100) * circumference;
  // const vacantStrokeDashOffset =
  //   ((100 - vacantPercentage) / 100) * circumference;

  return (
    <PieChart
      series={[
        {
          data: data,
        },
      ]}
      width={400}
      height={200}
    />
    // <svg width="200" height="250">
    //   {/* Rented Flats */}
    //   <circle
    //     cx="100"
    //     cy="100"
    //     r={radius}
    //     fill="none"
    //     stroke="green"
    //     strokeWidth="20"
    //     strokeDasharray={circumference}
    //     strokeDashoffset={rentedStrokeDashOffset}
    //     transform="rotate(-90 100 100)"
    //   />
    //   {/* Vacant Flats */}
    //   <circle
    //     cx="100"
    //     cy="100"
    //     r={radius}
    //     fill="none"
    //     stroke="red"
    //     strokeWidth="20"
    //     strokeDasharray={circumference}
    //     strokeDashoffset={vacantStrokeDashOffset}
    //   />
    //   {/* Center Circle */}
    //   <circle cx="100" cy="100" r="40" fill="white" />
    //   {/* Total Flats Text */}
    //   <text
    //     x="100"
    //     y="100"
    //     textAnchor="middle"
    //     dominantBaseline="middle"
    //     fontSize="16"
    //   >
    //     {`${totalFlats ?? "--"}`}
    //   </text>
    //   {/* Rented Flats Text */}
    //   <text x="70" y="200" textAnchor="end" fontSize="12" fill="green">
    //     {`Rented: ${rentedFlats}`}
    //   </text>
    //   {/* Vacant Flats Text */}
    //   <text x="120" y="200" textAnchor="start" fontSize="12" fill="red">
    //     {`Vacant: ${vacantFlats}`}
    //   </text>
    // </svg>
  );
};

export default CircularMeter;
